import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { AllowanceService } from 'services';
import {
  Grid, Typography, TableContainer, Table, TableRow, TableHead, TableBody, TableCell, Button
} from '@material-ui/core';
import styled from 'styled-components';
import { Datepicker } from '../../../_commons';
import { translate, BookingHelper } from 'utils';
import shortid from 'shortid';
import { useStores } from 'hooks';
import { action } from 'mobx';

const CustomTableCell = styled(TableCell)`
  font-size: 20px;
  &[data-selected="true"] {
    background-color: var(--secondary-color);
    color: var(--white);
  }
  &[data-na="true"] {
    background-color: var(--grey-darker);
    color: var(--white);
  }
`;

const CustomTableContainer = styled(TableContainer)`
  border: 2px solid;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const PriceContainer = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const MAX_ALLOWED_DAYS = 15;
const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

const BookingByNight = ({ allowance, dateRange, formState, select, setDateRange, setInterEstablishmentID, price, setPrice, setIsError, isError, userAllowanceApplicationDetail, userTariffRange }) => {
  const { allowanceStore } = useStores();
  const { allowanceHighSeasons } = allowanceStore;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [availabilityList, setAvailabilityList] = useState([]);
  const [availableEstablishments, setAvailableEstablishments] = useState([]);
  const [totalDays, setTotalDays] = useState(0);
  const [validationError, setValidationError] = useState(null);
  const [isDateValid, setIsDateValid] = useState(true);
  
  const validateDateRange = useCallback(async (startDate, endDate) => {
    if (startDate > endDate) {
      setValidationError(translate('pageBookingByNight.invalidDates'));
      return false;
    }
    if ((!startDate || !endDate || Math.abs(endDate - startDate) / MILLISECONDS_PER_DAY > MAX_ALLOWED_DAYS)) {
      setValidationError(translate('pageBookingByNight.maxDays'));
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    let errorMessage = null;
    const isNoRoomAvailable = availableEstablishments.length > 0 && availableEstablishments.every(item => !item.availability);
    const isTooManyDays = startDate && endDate && Math.abs(endDate - startDate) / MILLISECONDS_PER_DAY > MAX_ALLOWED_DAYS;
    const isValidSelected = startDate && endDate && startDate > endDate

    if (isNoRoomAvailable) {
      errorMessage = translate('pageBookingByNight.noRoomAvailable');
    }
    if (isTooManyDays) {
      errorMessage = translate('pageBookingByNight.maxDays');
    }
    if (isValidSelected){
      errorMessage = translate('pageBookingByNight.invalidDates');
    }
    setValidationError(errorMessage);
  }, [availableEstablishments, startDate, endDate]);

  const handleDateChange = (type, date) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  useEffect(() => {
    const isValid = startDate && endDate && startDate < endDate && Math.abs(endDate - startDate) / MILLISECONDS_PER_DAY <= MAX_ALLOWED_DAYS;
    setIsDateValid(isValid);
  }, [startDate, endDate]);

  const calculateTotalDays = useCallback(() => {
    if (startDate && endDate) {
      const diffInMs = endDate - startDate;
      return Math.ceil(diffInMs / MILLISECONDS_PER_DAY);
    }
    return 0;
  }, [startDate, endDate]);

  const handleOnBookSelect = (item, index) => {
    const updatedItem = { ...item, dateBegin: startDate, dateEnd: endDate };
    setAvailableEstablishments(prevEstablishments =>
      prevEstablishments.map((prevItem, i) =>
        i === index ? updatedItem : prevItem
      )
    );
    formState.setField('price', updatedItem.price);
    formState.setField('interEstablishmentAllowanceId', updatedItem.interEstablishmentId);
    setInterEstablishmentID(getInterEstablishmentID(updatedItem));
    if (BookingHelper.checkIfHighSeason(allowanceHighSeasons, startDate)) {
      setPrice(updatedItem.priceHighSeason);
    } else {
      setPrice(updatedItem.price);
    }
    const newDateRange = [new Date(updatedItem.dateBegin), new Date(updatedItem.dateEnd)];
    setDateRange(newDateRange);
  };

  const getInterEstablishmentID = useCallback(item => allowance.interEstablishments.find(interEstablishment => item.name === interEstablishment.name).id, [allowance]);

  useEffect(() => {
    if (startDate && endDate && validateDateRange(startDate, endDate)) {
      const bookingWithoutAvailability = BookingHelper.getFormattedBookingByDay(
        allowance,
        userTariffRange
      );
      const totalDays = calculateTotalDays();
      setTotalDays(totalDays);
      bookingWithoutAvailability.forEach(availableEstablishment => {
        const dateBegin = new Date(availableEstablishment.dateBegin);
        setInterEstablishmentID(getInterEstablishmentID(availableEstablishment));
        const pricePerNight = BookingHelper.checkIfHighSeason(allowanceHighSeasons, dateBegin) ? availableEstablishment.priceHighSeason : availableEstablishment.price;
        setPrice(pricePerNight * totalDays);
      });

      let internalId = 0;
      let requestedAvailabilities = [];
      for (const booking of bookingWithoutAvailability) {
        const internalIdCount = ++internalId;
        const updatedBooking = {
          ...booking,
          dateBegin: startDate,
          dateEnd: endDate,
          internalId: internalIdCount
        };
        requestedAvailabilities.push(extractRequestedAvailabilities(updatedBooking, internalIdCount));
      }
      requestedAvailabilities = requestedAvailabilities.filter(item => item !== undefined)
      AllowanceService.getAvailabilities(requestedAvailabilities).then(action(result => {
        const mappingInternalId = new Map(result.map(i => [
          i.internalId, i.isAvailable
        ]));
        const mappingInteretablisment = new Map(requestedAvailabilities.map(x => [
          x.interEstablishmentId, mappingInternalId.get(x.internalId)
        ]));
        const bookingWithAvailability = bookingWithoutAvailability.map(i => ({
          ...i,
          dateBegin: startDate,
          dateEnd: endDate,
          availability: mappingInteretablisment.get(i.interEstablishmentId)
        }));
        setAvailableEstablishments(bookingWithAvailability);
      }))
        .catch(error => {
          setAvailableEstablishments([]);
        });
    }
  }, [startDate, endDate, allowance, calculateTotalDays]);

  function extractRequestedAvailabilities(booking, internalId) {
    if (booking.interEstablishmentId === undefined) {
      return undefined;
    }

    const item = {};
    booking.internalId = internalId;
    item.interEstablishmentId = booking.interEstablishmentId;
    item.internalId = booking.internalId;
    item.startDate = booking.dateBegin;
    item.endDate = booking.dateEnd;
    return item;
  }

  return (
    <Grid container direction="column" spacing={2}>
      {validationError && (
        <Grid item>
          <Typography color="error">{validationError}</Typography>
        </Grid>
      )}
      <Typography>{translate('pageBookingByNight.selectMonth')}</Typography>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Datepicker
            disablePast
            label={translate('Date de début')}
            value={startDate}
            onChange={(date) => handleDateChange('start', date)}
            views={['year', 'month', 'date']}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Datepicker
            disablePast
            label={translate('Date de fin')}
            value={endDate}
            onChange={(date) => handleDateChange('end', date)}
            views={['year', 'month', 'date']}
          />
        </Grid>
      </Grid>

      {availableEstablishments.length > 0 && isDateValid && (
        <CustomTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>{translate('Logement')}</CustomTableCell>
                <CustomTableCell align="center">{translate('Capacité')}</CustomTableCell>
                <CustomTableCell align="center">{translate('Prix')}</CustomTableCell>
                <CustomTableCell align="center"></CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableEstablishments.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <CustomTableCell>{item.name}</CustomTableCell>
                    <CustomTableCell align="center">1x{item.capacity} Couchages</CustomTableCell>
                    <CustomTableCell align="center">
                      <PriceContainer>
                        {`${item.price * totalDays}€`}
                      </PriceContainer>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {item.availability ? (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleOnBookSelect(item, index)}
                        >
                          {translate('pageBookingByNight.book')}
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          disabled="true"
                          color="secondary">
                          {translate('pageBookingByNight.unavailable')}
                        </Button>
                      )}
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CustomTableContainer>
      )}

      {availableEstablishments.length === 0 && (
        <Grid item>
          <Typography>{translate('pageBookingByNight.nobookingAvailable')}</Typography>
        </Grid>
      )}
      <Grid item>
      </Grid>
    </Grid>
  );
};

export default BookingByNight;